var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._t("close",function(){return [_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("閉じる")])]}),_c('v-spacer'),_vm._v(" チーム情報編集 "),_c('v-spacer')],2),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("月別 ")]),_c('v-tab',[_vm._v("情報")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('DateTime',{attrs:{"label":"設定月","hideTime":"","month":""},on:{"commit":_vm.onDateChanged},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(" 設定月: "+_vm._s(_vm._f("dateMonth")(_vm.quotaDate))+" ")])]}}],null,true),model:{value:(_vm.quotaDate),callback:function ($$v) {_vm.quotaDate=$$v},expression:"quotaDate"}})],1),(_vm.quotaEditor)?_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},[_c('v-tab',[_vm._v("目標設定")]),_c('v-tab',[_vm._v("実績確認")])],1),_c('v-tabs-items',{model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},[_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[(_vm.editor.sectionType == 1 << 4)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("予算")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"宿泊売上","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"宿泊売上","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.stay),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "stay", _vm._n($$v))},expression:"quotaEditor.stay"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"飲食売上","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"飲食売上","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.eatingAndDrinking),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "eatingAndDrinking", _vm._n($$v))},expression:"quotaEditor.eatingAndDrinking"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"日帰り売上(温浴)","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"日帰り売上(温浴)","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.dayStay),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "dayStay", _vm._n($$v))},expression:"quotaEditor.dayStay"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"併設(売店)売上","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"併設(売店)売上","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.store),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "store", _vm._n($$v))},expression:"quotaEditor.store"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"その他","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"その他","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.other),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "other", _vm._n($$v))},expression:"quotaEditor.other"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"売上戻り","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"売上戻り","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.returnBack),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "returnBack", _vm._n($$v))},expression:"quotaEditor.returnBack"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"値引き","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"値引き","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.discount),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "discount", _vm._n($$v))},expression:"quotaEditor.discount"}})]}}],null,true)}),_c('div',{staticClass:"my-4 d-flex justify-space-between"},[_c('span',{staticClass:"text-h5"},[_vm._v("小計")]),_c('v-spacer'),_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm._f("priceJP")(_vm.quotaSubTotal)))])],1),_c('v-divider',{staticClass:"mt-2 mb-6"}),_c('validation-provider',{attrs:{"name":"修正予算","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"type":"number","label":"修正予算","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.correctedTotal),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "correctedTotal", _vm._n($$v))},expression:"quotaEditor.correctedTotal"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"修正予算理由","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"修正予算理由","rows":"3","counter":"","maxlength":1000,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.quotaEditor.correctedTotalMessage),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "correctedTotalMessage", $$v)},expression:"quotaEditor.correctedTotalMessage"}})]}}],null,true)}),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"my-4 d-flex justify-space-between"},[_c('span',{staticClass:"text-h4"},[_vm._v("合計")]),_c('v-spacer'),_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm._f("priceJP")(_vm.quotaTotal)))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("売上見込")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"宿泊売上","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"宿泊売上","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.prospectEditor.stay),callback:function ($$v) {_vm.$set(_vm.prospectEditor, "stay", _vm._n($$v))},expression:"prospectEditor.stay"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"飲食売上","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"飲食売上","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":"","disabled":""},model:{value:(_vm.salesEditor.eatingAndDrinking),callback:function ($$v) {_vm.$set(_vm.salesEditor, "eatingAndDrinking", _vm._n($$v))},expression:"salesEditor.eatingAndDrinking"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"日帰り売上(温浴)","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"日帰り売上(温浴)","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":"","disabled":""},model:{value:(_vm.salesEditor.dayStay),callback:function ($$v) {_vm.$set(_vm.salesEditor, "dayStay", _vm._n($$v))},expression:"salesEditor.dayStay"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"併設(売店)売上","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"併設(売店)売上","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":"","disabled":""},model:{value:(_vm.salesEditor.store),callback:function ($$v) {_vm.$set(_vm.salesEditor, "store", _vm._n($$v))},expression:"salesEditor.store"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"その他","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"その他","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":"","disabled":""},model:{value:(_vm.salesEditor.other),callback:function ($$v) {_vm.$set(_vm.salesEditor, "other", _vm._n($$v))},expression:"salesEditor.other"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"売上戻り","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"売上戻り","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":"","disabled":""},model:{value:(_vm.salesEditor.returnBack),callback:function ($$v) {_vm.$set(_vm.salesEditor, "returnBack", _vm._n($$v))},expression:"salesEditor.returnBack"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"値引き","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"値引き","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"readonly":"","dense":"","disabled":""},model:{value:(_vm.salesEditor.discount),callback:function ($$v) {_vm.$set(_vm.salesEditor, "discount", _vm._n($$v))},expression:"salesEditor.discount"}})]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("実績")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"宿泊売上","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"宿泊売上","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.stay),callback:function ($$v) {_vm.$set(_vm.salesEditor, "stay", _vm._n($$v))},expression:"salesEditor.stay"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"飲食売上","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"飲食売上","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.eatingAndDrinking),callback:function ($$v) {_vm.$set(_vm.salesEditor, "eatingAndDrinking", _vm._n($$v))},expression:"salesEditor.eatingAndDrinking"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"日帰り売上(温浴)","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"日帰り売上(温浴)","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.dayStay),callback:function ($$v) {_vm.$set(_vm.salesEditor, "dayStay", _vm._n($$v))},expression:"salesEditor.dayStay"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"併設(売店)売上","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"併設(売店)売上","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.store),callback:function ($$v) {_vm.$set(_vm.salesEditor, "store", _vm._n($$v))},expression:"salesEditor.store"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"その他","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"その他","prefix":"￥","hint":"*伝票に指定月1日に「その他合計売上」で一行追加されます","persistent-hint":"","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.other),callback:function ($$v) {_vm.$set(_vm.salesEditor, "other", _vm._n($$v))},expression:"salesEditor.other"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"売上戻り","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"売上戻り","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.returnBack),callback:function ($$v) {_vm.$set(_vm.salesEditor, "returnBack", _vm._n($$v))},expression:"salesEditor.returnBack"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"値引き","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"値引き","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.discount),callback:function ($$v) {_vm.$set(_vm.salesEditor, "discount", _vm._n($$v))},expression:"salesEditor.discount"}})]}}],null,true)})],1)],1)],1)],1)],1):_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("予算")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"システム：新規","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"type":"number","label":"システム：新規","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.systemNew),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "systemNew", _vm._n($$v))},expression:"quotaEditor.systemNew"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"システム：リプ","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"type":"number","label":"システム：リプ","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.systemReplace),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "systemReplace", _vm._n($$v))},expression:"quotaEditor.systemReplace"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"すけさん","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"type":"number","label":"すけさん","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.sukesan),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "sukesan", _vm._n($$v))},expression:"quotaEditor.sukesan"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"宿ぷらす(業販)","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"type":"number","label":"宿ぷらす(業販)","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.yadoPlus),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "yadoPlus", _vm._n($$v))},expression:"quotaEditor.yadoPlus"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"レンタル","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"type":"number","label":"レンタル","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.rental),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "rental", _vm._n($$v))},expression:"quotaEditor.rental"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Web","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"type":"number","label":"Web","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.web),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "web", _vm._n($$v))},expression:"quotaEditor.web"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"その他","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"その他","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.other),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "other", _vm._n($$v))},expression:"quotaEditor.other"}})]}}],null,true)}),_c('div',{staticClass:"my-4 d-flex justify-space-between"},[_c('span',{staticClass:"text-h5"},[_vm._v("小計")]),_c('v-spacer'),_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm._f("priceJP")(_vm.quotaSubTotal)))])],1),_c('v-divider',{staticClass:"mt-2 mb-6"}),_c('validation-provider',{attrs:{"name":"修正予算","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"type":"number","label":"修正予算","prefix":"￥","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.quotaEditor.correctedTotal),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "correctedTotal", _vm._n($$v))},expression:"quotaEditor.correctedTotal"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"修正予算理由","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"修正予算理由","rows":"3","counter":"","maxlength":1000,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.quotaEditor.correctedTotalMessage),callback:function ($$v) {_vm.$set(_vm.quotaEditor, "correctedTotalMessage", $$v)},expression:"quotaEditor.correctedTotalMessage"}})]}}],null,true)}),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"my-4 d-flex justify-space-between"},[_c('span',{staticClass:"text-h4"},[_vm._v("合計")]),_c('v-spacer'),_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm._f("priceJP")(_vm.quotaTotal)))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("実績")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"システム：新規","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"システム：新規","placeholder":"デモ結果データから自動算出されます","persistent-placeholder":"","counter":"","maxlength":10,"error-messages":errors,"dense":"","disabled":""}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"システム：リプ","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"システム：リプ","placeholder":"デモ結果データから自動算出されます","persistent-placeholder":"","counter":"","maxlength":10,"error-messages":errors,"dense":"","disabled":""}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"すけさん","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"すけさん","prefix":"￥","hint":"*伝票に指定月1日に「すけさん合計売上」で一行追加されます","persistent-hint":"","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.sukesan),callback:function ($$v) {_vm.$set(_vm.salesEditor, "sukesan", _vm._n($$v))},expression:"salesEditor.sukesan"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"宿ぷらす(業販)","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"宿ぷらす(業販)","prefix":"￥","hint":"*伝票に指定月1日に「宿ぷらす(業販)合計売上」で一行追加されます","persistent-hint":"","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.yadoPlus),callback:function ($$v) {_vm.$set(_vm.salesEditor, "yadoPlus", _vm._n($$v))},expression:"salesEditor.yadoPlus"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"レンタル","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"type":"number","label":"レンタル","prefix":"￥","hint":"*伝票に指定月1日に「レンタル合計売上」で一行追加されます","persistent-hint":"","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.rental),callback:function ($$v) {_vm.$set(_vm.salesEditor, "rental", _vm._n($$v))},expression:"salesEditor.rental"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Web","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"type":"number","label":"Web","prefix":"￥","hint":"*伝票に指定月1日に「Web合計売上」で一行追加されます","persistent-hint":"","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.web),callback:function ($$v) {_vm.$set(_vm.salesEditor, "web", _vm._n($$v))},expression:"salesEditor.web"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"その他","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"その他","prefix":"￥","hint":"*伝票に指定月1日に「その他合計売上」で一行追加されます","persistent-hint":"","counter":"","maxlength":10,"error-messages":errors,"dense":""},model:{value:(_vm.salesEditor.other),callback:function ($$v) {_vm.$set(_vm.salesEditor, "other", _vm._n($$v))},expression:"salesEditor.other"}})]}}],null,true)})],1)],1)],1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" システム新規 ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-2 mb-4",attrs:{"headers":_vm.headers1,"items":_vm.tableDatas1,"item-key":"name","items-per-page":-1,"dense":""}}),_c('v-card-title',[_vm._v(" システムリプレース ")]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers2,"items":_vm.tableDatas2,"item-key":"name","items-per-page":-1,"dense":""}})],1)],1)],1)],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onUpdateQuota}},[_vm._v("実績・目標登録")])],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"名称","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"名称","counter":"","maxlength":100,"error-messages":errors,"dense":"","outlined":""},model:{value:(_vm.editor.name),callback:function ($$v) {_vm.$set(_vm.editor, "name", $$v)},expression:"editor.name"}})]}}],null,true)}),_c('XFieldSet',{staticClass:"mb-4",attrs:{"label":"RSViewer設定"}},[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("branch_id")]),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"branch_id","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","counter":"","maxlength":32,"error-messages":errors,"dense":""},model:{value:(_vm.editor.branch_id),callback:function ($$v) {_vm.$set(_vm.editor, "branch_id", _vm._n($$v))},expression:"editor.branch_id"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("group_id")]),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"group_id","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","counter":"","maxlength":32,"error-messages":errors,"dense":""},model:{value:(_vm.editor.group_id),callback:function ($$v) {_vm.$set(_vm.editor, "group_id", _vm._n($$v))},expression:"editor.group_id"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("特殊設定")]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":"支店長チーム","value":_vm.TeamMarkTypes.Leader},model:{value:(_vm.editor.teamMarkType),callback:function ($$v) {_vm.$set(_vm.editor, "teamMarkType", $$v)},expression:"editor.teamMarkType"}})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v("ダミー")]),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"ダミー","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-4",attrs:{"error-messages":errors,"dense":""},model:{value:(_vm.editor.isDummy),callback:function ($$v) {_vm.$set(_vm.editor, "isDummy", $$v)},expression:"editor.isDummy"}})]}}],null,true)})],1)],1)],1),_c('XFieldSet',{attrs:{"label":"メンバー"}},[_c('v-list',[_vm._l((_vm.editor.employees),function(employee,index){return [_c('v-list-item',{key:("list-item" + index)},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(employee.name)}})],1),_c('v-list-item-content',[_c('ul',_vm._l((employee.meta.emails.filter(function (a) { return a.isSend; })),function(val,j){return _c('li',{key:j,attrs:{"small":""}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(val.email)+" ("+_vm._s(_vm._f("emailType")(val.type))+")")])])}),0)])],1)]})],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onUpdate}},[_vm._v("登録")])],1)],1)],1)],1)],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }