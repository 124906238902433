<template>
  <v-card>
    <v-card-title>
      <slot name="close">
        <v-btn text color="warning" @click="$emit('cancel')">閉じる</v-btn>
      </slot>
      <v-spacer />
      チーム情報編集
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <v-tabs v-model="tab">
              <v-tab>月別 </v-tab>
              <v-tab>情報</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card outlined>
                  <v-card-title>
                    <!-- {{ quotaDate }}
                    <v-select label="設定月" v-model="quotaDate" :items="dates" @change="onDateChanged" dense outlined /> -->

                    <DateTime label="設定月" v-model="quotaDate" hideTime month @commit="onDateChanged">
                      <template v-slot="{ click }">
                        <v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"> 設定月: {{ quotaDate | dateMonth }} </v-btn>
                      </template>
                    </DateTime>
                  </v-card-title>
                  <v-card-text v-if="quotaEditor">
                    <v-tabs v-model="tab2">
                      <v-tab>目標設定</v-tab>
                      <v-tab>実績確認</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab2">
                      <v-tab-item>
                        <v-card outlined>
                          <v-card-text v-if="editor.sectionType == 1 << 4">
                            <v-row>
                              <v-col cols="4">
                                <v-card flat>
                                  <v-card-title>予算</v-card-title>
                                  <v-card-text>
                                    <!-- 旅館事業用-->
                                    <validation-provider v-slot="{ errors }" name="宿泊売上" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="宿泊売上"
                                        prefix="￥"
                                        v-model.number="quotaEditor.stay"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="飲食売上" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="飲食売上"
                                        prefix="￥"
                                        v-model.number="quotaEditor.eatingAndDrinking"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="日帰り売上(温浴)" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="日帰り売上(温浴)"
                                        prefix="￥"
                                        v-model.number="quotaEditor.dayStay"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="併設(売店)売上" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="併設(売店)売上"
                                        prefix="￥"
                                        v-model.number="quotaEditor.store"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="その他" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="その他"
                                        prefix="￥"
                                        v-model.number="quotaEditor.other"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="売上戻り" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="売上戻り"
                                        prefix="￥"
                                        v-model.number="quotaEditor.returnBack"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="値引き" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="値引き"
                                        prefix="￥"
                                        v-model.number="quotaEditor.discount"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>

                                    <div class="my-4 d-flex justify-space-between">
                                      <span class="text-h5">小計</span>
                                      <v-spacer />
                                      <span class="text-h5">{{ quotaSubTotal | priceJP }}</span>
                                    </div>

                                    <v-divider class="mt-2 mb-6" />

                                    <validation-provider v-slot="{ errors }" name="修正予算" rules="max:100">
                                      <v-text-field
                                        class="mb-4"
                                        type="number"
                                        label="修正予算"
                                        prefix="￥"
                                        v-model.number="quotaEditor.correctedTotal"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="修正予算理由" rules="max:100">
                                      <v-textarea
                                        label="修正予算理由"
                                        v-model="quotaEditor.correctedTotalMessage"
                                        rows="3"
                                        counter
                                        :maxlength="1000"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                      />
                                    </validation-provider>

                                    <v-divider class="my-4" />

                                    <div class="my-4 d-flex justify-space-between">
                                      <span class="text-h4">合計</span>
                                      <v-spacer />
                                      <span class="text-h4">{{ quotaTotal | priceJP }}</span>
                                    </div>
                                  </v-card-text>
                                </v-card>
                              </v-col>

                              <v-col cols="4">
                                <v-card flat>
                                  <v-card-title>売上見込</v-card-title>
                                  <v-card-text>
                                    <!-- 旅館事業用-->
                                    <validation-provider v-slot="{ errors }" name="宿泊売上" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="宿泊売上"
                                        prefix="￥"
                                        v-model.number="prospectEditor.stay"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="飲食売上" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="飲食売上"
                                        prefix="￥"
                                        v-model.number="salesEditor.eatingAndDrinking"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                        disabled
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="日帰り売上(温浴)" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="日帰り売上(温浴)"
                                        prefix="￥"
                                        v-model.number="salesEditor.dayStay"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                        disabled
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="併設(売店)売上" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="併設(売店)売上"
                                        prefix="￥"
                                        v-model.number="salesEditor.store"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                        disabled
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="その他" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="その他"
                                        prefix="￥"
                                        v-model.number="salesEditor.other"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                        disabled
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="売上戻り" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="売上戻り"
                                        prefix="￥"
                                        v-model.number="salesEditor.returnBack"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                        disabled
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="値引き" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="値引き"
                                        prefix="￥"
                                        v-model.number="salesEditor.discount"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        readonly
                                        dense
                                        disabled
                                      />
                                    </validation-provider>
                                  </v-card-text>
                                </v-card>
                              </v-col>

                              <v-col cols="4">
                                <v-card flat>
                                  <v-card-title>実績</v-card-title>
                                  <v-card-text>
                                    <!-- 旅館事業用-->
                                    <validation-provider v-slot="{ errors }" name="宿泊売上" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="宿泊売上"
                                        prefix="￥"
                                        v-model.number="salesEditor.stay"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="飲食売上" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="飲食売上"
                                        prefix="￥"
                                        v-model.number="salesEditor.eatingAndDrinking"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="日帰り売上(温浴)" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="日帰り売上(温浴)"
                                        prefix="￥"
                                        v-model.number="salesEditor.dayStay"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="併設(売店)売上" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="併設(売店)売上"
                                        prefix="￥"
                                        v-model.number="salesEditor.store"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="その他" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="その他"
                                        prefix="￥"
                                        v-model.number="salesEditor.other"
                                        hint="*伝票に指定月1日に「その他合計売上」で一行追加されます"
                                        persistent-hint
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="売上戻り" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="売上戻り"
                                        prefix="￥"
                                        v-model.number="salesEditor.returnBack"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="値引き" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="値引き"
                                        prefix="￥"
                                        v-model.number="salesEditor.discount"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card-text>

                          <v-card-text v-else>
                            <v-row>
                              <v-col cols="6">
                                <v-card flat>
                                  <v-card-title>予算</v-card-title>
                                  <v-card-text>
                                    <validation-provider v-slot="{ errors }" name="システム：新規" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        class="mb-5"
                                        label="システム：新規"
                                        prefix="￥"
                                        v-model.number="quotaEditor.systemNew"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="システム：リプ" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        class="mb-5"
                                        label="システム：リプ"
                                        prefix="￥"
                                        v-model.number="quotaEditor.systemReplace"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="すけさん" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        class="mb-5"
                                        label="すけさん"
                                        prefix="￥"
                                        v-model.number="quotaEditor.sukesan"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="宿ぷらす(業販)" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        class="mb-5"
                                        label="宿ぷらす(業販)"
                                        prefix="￥"
                                        v-model.number="quotaEditor.yadoPlus"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="レンタル" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        class="mb-5"
                                        label="レンタル"
                                        prefix="￥"
                                        v-model.number="quotaEditor.rental"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="Web" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        class="mb-5"
                                        label="Web"
                                        prefix="￥"
                                        v-model.number="quotaEditor.web"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="その他" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="その他"
                                        prefix="￥"
                                        v-model.number="quotaEditor.other"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>

                                    <div class="my-4 d-flex justify-space-between">
                                      <span class="text-h5">小計</span>
                                      <v-spacer />
                                      <span class="text-h5">{{ quotaSubTotal | priceJP }}</span>
                                    </div>

                                    <v-divider class="mt-2 mb-6" />

                                    <validation-provider v-slot="{ errors }" name="修正予算" rules="max:100">
                                      <v-text-field
                                        class="mb-4"
                                        type="number"
                                        label="修正予算"
                                        prefix="￥"
                                        v-model.number="quotaEditor.correctedTotal"
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="修正予算理由" rules="max:100">
                                      <v-textarea
                                        label="修正予算理由"
                                        v-model="quotaEditor.correctedTotalMessage"
                                        rows="3"
                                        counter
                                        :maxlength="1000"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                      />
                                    </validation-provider>

                                    <v-divider class="my-4" />

                                    <div class="my-4 d-flex justify-space-between">
                                      <span class="text-h4">合計</span>
                                      <v-spacer />
                                      <span class="text-h4">{{ quotaTotal | priceJP }}</span>
                                    </div>
                                  </v-card-text>
                                </v-card>
                              </v-col>

                              <v-col cols="6">
                                <v-card flat>
                                  <v-card-title>実績</v-card-title>
                                  <v-card-text>
                                    <validation-provider v-slot="{ errors }" name="システム：新規" rules="max:100">
                                      <v-text-field
                                        class="mb-5"
                                        label="システム：新規"
                                        placeholder="デモ結果データから自動算出されます"
                                        persistent-placeholder
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                        disabled
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="システム：リプ" rules="max:100">
                                      <v-text-field
                                        class="mb-5"
                                        label="システム：リプ"
                                        placeholder="デモ結果データから自動算出されます"
                                        persistent-placeholder
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                        disabled
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="すけさん" rules="max:100">
                                      <v-text-field
                                        class="mb-5"
                                        label="すけさん"
                                        prefix="￥"
                                        v-model.number="salesEditor.sukesan"
                                        hint="*伝票に指定月1日に「すけさん合計売上」で一行追加されます"
                                        persistent-hint
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>

                                    <validation-provider v-slot="{ errors }" name="宿ぷらす(業販)" rules="max:100">
                                      <v-text-field
                                        class="mb-5"
                                        label="宿ぷらす(業販)"
                                        prefix="￥"
                                        v-model.number="salesEditor.yadoPlus"
                                        hint="*伝票に指定月1日に「宿ぷらす(業販)合計売上」で一行追加されます"
                                        persistent-hint
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="レンタル" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        class="mb-5"
                                        label="レンタル"
                                        prefix="￥"
                                        v-model.number="salesEditor.rental"
                                        hint="*伝票に指定月1日に「レンタル合計売上」で一行追加されます"
                                        persistent-hint
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="Web" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        class="mb-5"
                                        label="Web"
                                        prefix="￥"
                                        v-model.number="salesEditor.web"
                                        hint="*伝票に指定月1日に「Web合計売上」で一行追加されます"
                                        persistent-hint
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="その他" rules="max:100">
                                      <v-text-field
                                        type="number"
                                        label="その他"
                                        prefix="￥"
                                        v-model.number="salesEditor.other"
                                        hint="*伝票に指定月1日に「その他合計売上」で一行追加されます"
                                        persistent-hint
                                        counter
                                        :maxlength="10"
                                        :error-messages="errors"
                                        dense
                                      />
                                    </validation-provider>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>

                      <v-tab-item>
                        <v-card outlined>
                          <v-card-title> システム新規 </v-card-title>
                          <v-card-text>
                            <v-data-table
                              class="elevation-2 mb-4"
                              :headers="headers1"
                              :items="tableDatas1"
                              item-key="name"
                              :items-per-page="-1"
                              dense
                            ></v-data-table>

                            <v-card-title> システムリプレース </v-card-title>
                            <v-data-table
                              class="elevation-2"
                              :headers="headers2"
                              :items="tableDatas2"
                              item-key="name"
                              :items-per-page="-1"
                              dense
                            ></v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="onUpdateQuota">実績・目標登録</v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card outlined>
                  <v-card-text>
                    <validation-provider v-slot="{ errors }" name="名称" rules="max:100">
                      <v-text-field
                        class="mt-4"
                        label="名称"
                        v-model="editor.name"
                        counter
                        :maxlength="100"
                        :error-messages="errors"
                        dense
                        outlined
                      />
                    </validation-provider>

                    <XFieldSet class="mb-4" label="RSViewer設定">
                      <v-row align="center" dense>
                        <v-col cols="2">branch_id</v-col
                        ><v-col cols="4">
                          <validation-provider v-slot="{ errors }" name="branch_id" rules="max:100">
                            <v-text-field
                              type="number"
                              v-model.number="editor.branch_id"
                              counter
                              :maxlength="32"
                              :error-messages="errors"
                              dense
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <v-row align="center" dense>
                        <v-col cols="2">group_id</v-col
                        ><v-col cols="4">
                          <validation-provider v-slot="{ errors }" name="group_id" rules="max:100">
                            <v-text-field
                              type="number"
                              v-model.number="editor.group_id"
                              counter
                              :maxlength="32"
                              :error-messages="errors"
                              dense
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <v-row align="center" dense>
                        <v-col cols="2">特殊設定</v-col
                        ><v-col cols="4">
                          <v-checkbox v-model="editor.teamMarkType" label="支店長チーム" :value="TeamMarkTypes.Leader"></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row align="center" dense>
                        <v-col cols="2">ダミー</v-col
                        ><v-col cols="4">
                          <validation-provider v-slot="{ errors }" name="ダミー" rules="">
                            <v-switch class="mt-4" v-model="editor.isDummy" :error-messages="errors" dense />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </XFieldSet>

                    <XFieldSet label="メンバー">
                      <v-list>
                        <template v-for="(employee, index) in editor.employees">
                          <v-list-item :key="`list-item${index}`">
                            <v-list-item-content>
                              <v-list-item-title v-html="employee.name"></v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-content>
                              <ul>
                                <li v-for="(val, j) of employee.meta.emails.filter((a) => a.isSend)" small :key="j">
                                  <span class="text-caption">{{ val.email }} ({{ val.type | emailType }})</span>
                                </li>
                              </ul>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </XFieldSet>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onUpdate">登録</v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </template>
      </XSheet>
    </v-card-text>
  </v-card>
</template>

<script>
import { TeamMarkTypes } from '@/types';
export default {
  props: {
    observer: {
      type: Object,
      required: true,
    },

    teamCode: {
      type: String,
      require: true,
    },

    show: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    TeamMarkTypes() {
      return TeamMarkTypes;
    },

    form() {
      return {
        loading: () => this.editor.loading,
        disabled: () => this.editor.loading || this.initializing,
        canSubmit: () => !this.editor.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },

    quotaEditor() {
      let val = this.$moment(this.quotaDate).format('YYYY-MM-DD');
      // let d = this.quotes.find((a) => a.date == this.quotaDate);
      let d = this.quotes.find((a) => a.date == val);
      return d ? d : {};
    },

    prospectEditor() {
      let val = this.$moment(this.quotaDate).format('YYYY-MM-DD');
      let d = this.prospects.find((a) => a.date == val);

      let data = d ? d : {};
      // 旅館事業部の場合、「宿泊売上」以外は実績の値を使用する
      // editに値を入れようか検討したが、入力値があると混乱するのでやめた
      return data;

      // if (this.editor.sectionType == 1 << 4) {
      //   data.eatingAndDrinking = this.salesEditor.eatingAndDrinking;
      //   data.dayStay = this.salesEditor.dayStay;
      //   data.store = this.salesEditor.store;
      //   data.other = this.salesEditor.other;
      //   data.returnBack = this.salesEditor.returnBack;
      //   data.discount = this.salesEditor.discount;
      // }
      // return data;
    },

    salesEditor() {
      let val = this.$moment(this.quotaDate).format('YYYY-MM-DD');

      // let d = this.sales.find((a) => a.date == this.quotaDate);
      let d = this.sales.find((a) => a.date == val);
      return d ? d : {};
    },

    quotaSubTotal() {
      let editor = this.quotaEditor;

      let total = 0;
      total += editor.systemNew;
      total += editor.systemReplace;
      total += editor.sukesan;
      total += editor.yadoPlus;
      total += editor.rental;
      total += editor.web;
      total += editor.other;

      total += editor.stay;
      total += editor.eatingAndDrinking;
      total += editor.dayStay;
      total += editor.store;
      total += editor.returnBack;
      total += editor.discount;

      return total;
    },

    quotaTotal() {
      let editor = this.quotaEditor;
      let total = this.quotaSubTotal;
      // 0は設定なしとみなす
      if (editor.correctedTotal) total = editor.correctedTotal;

      return total;
    },

    dates() {
      let d = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

      // return [{text:m.format("YYYY-MM-DD"),value:m.toString("yyyyMMdd")}]

      return [...Array(5).keys()].map((a) => {
        let m = this.$moment(d).add(a - 2, 'months');
        return {
          text: m.format('YYYY-MM'),
          // value: m.format('YYYY-MM-DD'),
          value: d,
        };
      });
    },

    headers1() {
      return [
        { text: '物件名', value: 'facility.name' },
        { text: 'アポ', value: 'apointer.name' },
        { text: 'デモ', value: 'demointer.name' },
        { text: 'リース月額', value: 'sales' },
        { text: '料率', value: 'leasePayment.rate' },
        { text: '買取他', value: 'info' },
        { text: '売上金額', value: 'sale' },
        { text: '開始確認', value: 'start' },
      ];
    },

    headers2() {
      return [
        { text: '物件名', value: 'facility.name' },
        { text: 'アポ', value: 'apointer.name' },
        { text: 'デモ', value: 'demointer.name' },
        { text: 'リース月額', value: 'sales' },
        { text: '料率', value: 'leasePayment.rate' },
        { text: '買取他', value: 'info' },
        { text: '売上金額', value: 'sale' },
        { text: '開始確認', value: 'start' },
      ];
    },

    tableDatas1() {
      return this.demos.filter((a) => a.contractType == 1);
    },
    tableDatas2() {
      return this.demos.filter((a) => a.contractType == 1 << 1);
    },

    isTeamMarkTypeLeader() {
      return (this.editor.meta.teamMarkType & TeamMarkTypes.Leader) != 0;
    },
  },

  data: () => ({
    initializing: true,

    // 分析中か？
    analysing: false,

    tab: 0,
    tab2: 0,

    quotes: [],
    prospects: [],
    sales: [],

    editor: {
      loading: false,

      name: null,
      branch_id: null,
      group_id: null,
      teamMarkType: null,
      isDummy: false,
      // 支店の情報のため読み取り専用
      sectionType: null,
    },

    // 対象月
    quotaDate: null,

    // デモデータ
    demos: [],
  }),
  methods: {
    /*
    // チームの分析データを取得します
    loadAnalyseTeam() {
      // 多段起動を防ぐため分析中は処理しない
      if (this.analysing) return;
      //
      this.analysing = true;
      this.get('analyse/team', {
        teamCode: this.teamCode,
        date: this.quotaDate,
      })
        .then((success) => {
          let data = success.data;
          this.demos = data.demos;
        })
        .catch(() => {
          //
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'warning',
            message: 'チーム分析情報が取得できませんでした',
          });
        })
        .finally(() => {
          this.analysing = false;
        });
    },
    */

    onLoaded() {
      // 当月月初を指定する
      // this.quotaDate = this.$moment().add(0, 'months').startOf('month').format('YYYY-MM-DD');
      this.quotaDate = this.$moment().add(0, 'months').startOf('month').toDate();

      //
      this.get('team', { teamCode: this.teamCode })
        .then((success) => {
          //
          let data = success.data;
          this.editor.name = data.name;
          this.editor.employees = data.employees;
          this.editor.group_id = data.meta.rsViewerGroup.group_id;
          this.editor.branch_id = data.meta.rsViewerGroup.branch_id;

          this.editor.teamMarkType = data.meta.teamMarkType;

          this.editor.isDummy = data.isDummy;

          this.editor.sectionType = data.sectionType;

          this.quotes = data.meta.quota.quotas.map((a) => ({
            date: this.$moment(a.date).format('YYYY-MM-DD'),
            systemNew: a.systemNew,
            systemReplace: a.systemReplace,
            sukesan: a.sukesan,
            yadoPlus: a.yadoPlus,
            rental: a.rental,
            web: a.web,
            other: a.other,

            stay: a.stay,
            eatingAndDrinking: a.eatingAndDrinking,
            dayStay: a.dayStay,
            store: a.store,
            returnBack: a.returnBack,
            discount: a.discount,

            correctedTotal: a.correctedTotal,
            correctedTotalMessage: a.correctedTotalMessage,
          }));

          this.prospects = data.meta.prospect.prospects.map((a) => ({
            date: this.$moment(a.date).format('YYYY-MM-DD'),
            systemNew: a.systemNew,
            systemReplace: a.systemReplace,
            sukesan: a.sukesan,
            yadoPlus: a.yadoPlus,
            rental: a.rental,
            web: a.web,
            other: a.other,

            stay: a.stay,
            eatingAndDrinking: a.eatingAndDrinking,
            dayStay: a.dayStay,
            store: a.store,
            returnBack: a.returnBack,
            discount: a.discount,

            correctedTotal: a.correctedTotal,
            correctedTotalMessage: a.correctedTotalMessage,
          }));

          //
          // for (let d of this.dates) {
          //   this.onDateChanged(d.value);
          // }
          //
          this.initializing = false;

          //
          // this.loadAnalyseTeam();
        })
        .catch(() => {
          //
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'warning',
            message: 'チーム情報が取得できませんでした',
          });
        });

      // 実績編集のため取得
      // ただしここではrental,web,otherとsukesanのsummary登録をするのみ
      this.get('team/sales', { teamCode: this.teamCode })
        .then((success) => {
          //
          let data = success.data;

          this.sales = data.map((a) => ({
            date: this.$moment(a.date).format('YYYY-MM-DD'),
            systemNew: a.systemNew,
            systemReplace: a.systemReplace,
            sukesan: a.sukesan,
            yadoPlus: a.yadoPlus,
            rental: a.rental,
            web: a.web,
            other: a.other,

            stay: a.stay,
            eatingAndDrinking: a.eatingAndDrinking,
            dayStay: a.dayStay,
            store: a.store,
            returnBack: a.returnBack,
            discount: a.discount,
          }));

          for (let d of this.dates) {
            this.onDateChanged(d.value);
          }
          //
          this.initializing = false;

          //
          // this.loadAnalyseTeam();
        })
        .catch(() => {
          //
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'warning',
            message: 'チーム売上設定が取得できませんでした',
          });
        });
    },

    onDateChanged(val1) {
      let val = this.$moment(val1).format('YYYY-MM-DD');
      console.log('val', val);
      // 予算
      {
        let d = this.quotes.find((a) => a.date == val);

        if (!d) {
          d = {
            date: val,
            systemNew: null,
            systemReplace: null,
            sukesan: null,
            yadoPlus: null,
            rental: null,
            web: null,
            other: null,

            stay: null,
            eatingAndDrinking: null,
            dayStay: null,
            store: null,
            returnBack: null,
            discount: null,

            correctedTotal: null,
            correctedTotalMessage: null,
          };
          this.quotes.push(d);
        }
      }

      // 見込み
      {
        let d = this.prospects.find((a) => a.date == val);

        if (!d) {
          d = {
            date: val,
            systemNew: null,
            systemReplace: null,
            sukesan: null,
            yadoPlus: null,
            rental: null,
            web: null,
            other: null,

            stay: null,
            eatingAndDrinking: null,
            dayStay: null,
            store: null,
            returnBack: null,
            discount: null,

            correctedTotal: null,
            correctedTotalMessage: null,
          };
          this.prospects.push(d);
        }
      }

      // 実績
      {
        let d2 = this.sales.find((a) => a.date == val);

        if (!d2) {
          d2 = {
            date: val,
            systemNew: null,
            systemReplace: null,
            sukesan: null,
            yadoPlus: null,
            rental: null,
            web: null,
            other: null,

            stay: null,
            eatingAndDrinking: null,
            dayStay: null,
            store: null,
            returnBack: null,
            discount: null,
          };
          this.sales.push(d2);
        }
      }

      // 初期起動中に発生するイベントでは処理しない
      // if (!this.initializing) {
      //   this.loadAnalyseTeam();
      // }
    },

    async onUpdate() {
      //
      if (!(await this.form.validate())) return;

      this.put('team', {
        teamCode: this.teamCode,
        name: this.editor.name,
        branch_id: this.editor.branch_id,
        group_id: this.editor.group_id,
        teamMarkType: this.editor.teamMarkType,
        isDummy: this.editor.isDummy,
      })
        .then(() => {
          //
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'success',
            message: 'チーム情報更新完了',
          });
        })
        .catch(() => {
          //
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'warning',
            message: 'チーム情報更新に失敗しました',
          });
        });
    },

    async onUpdateQuota() {
      //
      if (!(await this.form.validate())) return;

      //
      if (!confirm('登録してよろしいですか？')) return;

      let request1 = {
        teamCode: this.teamCode,
        quotes: this.quotes.map((a) => ({
          date: this.convertDate(a.date),
          systemNew: this.convertNumber(a.systemNew),
          systemReplace: this.convertNumber(a.systemReplace),
          sukesan: this.convertNumber(a.sukesan),
          yadoPlus: this.convertNumber(a.yadoPlus),
          rental: this.convertNumber(a.rental),
          web: this.convertNumber(a.web),
          other: this.convertNumber(a.other),

          stay: this.convertNumber(a.stay),
          eatingAndDrinking: this.convertNumber(a.eatingAndDrinking),
          dayStay: this.convertNumber(a.dayStay),
          store: this.convertNumber(a.store),
          returnBack: this.convertNumber(a.returnBack),
          discount: this.convertNumber(a.discount),

          correctedTotal: this.convertNumber(a.correctedTotal),
          correctedTotalMessage: a.correctedTotalMessage,
        })),

        prospects: this.prospects.map((a) => ({
          date: this.convertDate(a.date),
          systemNew: this.convertNumber(a.systemNew),
          systemReplace: this.convertNumber(a.systemReplace),
          sukesan: this.convertNumber(a.sukesan),
          yadoPlus: this.convertNumber(a.yadoPlus),
          rental: this.convertNumber(a.rental),
          web: this.convertNumber(a.web),
          other: this.convertNumber(a.other),

          stay: this.convertNumber(a.stay),
          eatingAndDrinking: this.convertNumber(a.eatingAndDrinking),
          dayStay: this.convertNumber(a.dayStay),
          store: this.convertNumber(a.store),
          returnBack: this.convertNumber(a.returnBack),
          discount: this.convertNumber(a.discount),

          correctedTotal: this.convertNumber(a.correctedTotal),
          correctedTotalMessage: a.correctedTotalMessage,
        })),
      };

      console.log('request1', request1);

      // 予算
      this.put('team/quotes', request1)
        .then(() => {
          //
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'success',
            message: 'チームノルマ情報更新完了',
          });
        })
        .catch((ex) => {
          console.log('ex', ex);
          //
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'warning',
            message: 'チームノルマ情報更新に失敗しました',
          });
        });

      // 実績もここで更新させてしまう
      let request2 = {
        teamCode: this.teamCode,
        sales: this.sales.map((a) => ({
          date: this.convertDate(a.date),
          systemNew: this.convertNumber(a.systemNew),
          systemReplace: this.convertNumber(a.systemReplace),
          sukesan: this.convertNumber(a.sukesan),
          yadoPlus: this.convertNumber(a.yadoPlus),
          rental: this.convertNumber(a.rental),
          web: this.convertNumber(a.web),
          other: this.convertNumber(a.other),

          stay: this.convertNumber(a.stay),
          eatingAndDrinking: this.convertNumber(a.eatingAndDrinking),
          dayStay: this.convertNumber(a.dayStay),
          store: this.convertNumber(a.store),
          returnBack: this.convertNumber(a.returnBack),
          discount: this.convertNumber(a.discount),
        })),
      };
      console.log('request2', request2);

      this.put('team/sale/summary/month', request2).catch((ex) => {
        console.log('ex2', ex);

        //
        this.$store.commit('setSnackbar', {
          show: true,
          color: 'warning',
          message: 'チーム実績情報更新に失敗しました',
        });
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>